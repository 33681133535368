import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CarouselLayout } from 'src/legacy/components/Carousel';
import { OnboardingAuthLayoutProps } from 'src/legacy/components/Onboarding/Login/OnboardingAuthLayout';
import { ConfirmForm } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingForgotPasswordForm/ConfirmForm';
import { AccountForm } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingForgotPasswordForm/AccountForm';
import { AuthLinkActionButton } from 'src/legacy/components/Auth/AuthLinkActionButton';

export type MfaFormProps = {
  onMfaRequired: (user: CognitoUser, email: string) => void;
};

type OnboardingForgotPasswordFormProps = OnboardingAuthLayoutProps &
  MfaFormProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigationActionsContainerDesktop: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    navigationActionsContainerMobile: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    returnPortalBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
  }),
);

export const OnboardingForgotPasswordForm = ({
  onAuthComplete,
  onMfaRequired,
}: OnboardingForgotPasswordFormProps) => {
  const classes = useStyles();

  const [email, setEmail] = React.useState('');

  // Using this state to determine which form to show to the user
  const [delivery, setDelivery] = React.useState<string | null>(null);

  const onSuccessSendCode = (deliveryMedium: string, userEmail: string) => {
    setDelivery(deliveryMedium);
    setEmail(userEmail);
  };

  // enable next button only if user has entered a valid username
  // and the code has been sent
  const checkNextEnabled = (currentStep: number) => {
    if (currentStep === 0) {
      if (email || delivery) return true;
      return false;
    }

    return false;
  };

  return (
    <div>
      <CarouselLayout
        hideAllActions
        hideIndicators
        length={2}
        nextEnabled={checkNextEnabled}
        step={delivery ? 1 : 0} // if delivery is set, go to second step
      >
        <Box>
          <AccountForm onSuccess={onSuccessSendCode} />
        </Box>
        <Box>
          <ConfirmForm
            email={email}
            onSuccess={onAuthComplete}
            onMfaRequired={onMfaRequired}
          />
        </Box>
      </CarouselLayout>
      <div className={classes.returnPortalBtnWrapper}>
        <AuthLinkActionButton linkText="Return to sign in" href="/login" />
      </div>
    </div>
  );
};
