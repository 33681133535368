export const Constants = {
  AUTH_SOURCE_KEY: 'amplify-react-auth-source',
  AUTH0: 'auth0',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  AMAZON: 'amazon',
  REDIRECTED_FROM_HOSTED_UI: 'amplify-redirected-from-hosted-ui',
};

export function objectWithProperties(
  obj: Record<string, any>,
  keys: Array<any>,
) {
  const target: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    if (keys.indexOf(key) === -1) return;
    if (!Object.prototype.hasOwnProperty.call(obj, key)) return;
    target[key] = obj[key];
  });
  return target;
}
