import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Auth, I18n } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  BaseTextField,
  SharpOutlinedTextField,
} from 'src/legacy/components/TextField';
import OnboardingButton from 'src/legacy/components/Onboarding/OnboardingButton';
import UsersClient from 'src/clients/UsersClient';
import { FormInputsContainer } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingLoginForm';
import { FlagsContext } from 'src/context';
import { OnboardingFormError } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingFormError';
import { getFormErrorText } from 'src/legacy/components/Onboarding/OnboardingForms/CreatePortalForm';

type ConfirmFormProps = {
  email: string;
  onSuccess: () => void;
  onMfaRequired: (user: CognitoUser, email: string) => void;
};
const confirmFormSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .min(6, 'Code must be exactly 6 digits.')
    .max(6, 'Code must be exactly 6 digits.')
    .required('Code is required'),
  password: Yup.string()
    .max(256, 'Password must be less than 256 characters')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must have at least one lowercase char')
    .matches(/[A-Z]/, 'Password must have at least one uppercase char')
    .matches(/[0-9]+/, 'Password must have at least one number')
    .required('Password is required'),
});

type ResetPasswordFormFields = {
  code: string;
  password: string;
};
export const ConfirmForm: React.FC<ConfirmFormProps> = ({
  email,
  onSuccess,
  onMfaRequired,
}) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const TextFieldInputComponent = GoogleLoginForInternalUser
    ? SharpOutlinedTextField
    : BaseTextField;
  const handleResetPassword = async (values: ResetPasswordFormFields) => {
    try {
      const { code, password } = values;
      if (!email || !password || !code) return;

      await Auth.forgotPasswordSubmit(email, code, password);
      // auto sign in user
      const user = await Auth.signIn({ username: email, password });

      // if additional challenges need to be resolved first
      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // this will render the OnboardingAuth with this user, which will
        // complete the sign in after verifying the flow
        onMfaRequired(user, email);
        return;
      }

      await UsersClient.setAppUserCookie(
        user,
        'workspace_login',
        'workspaces',
        email,
      );

      onSuccess();
    } catch (error) {
      if (error instanceof Error) {
        if (error.name && error.name === 'CodeMismatchException') {
          setErrorMessage('Incorrect code, please try again');
        } else {
          setErrorMessage(error.message);
        }
      }
    }
  };

  return (
    <Formik
      initialValues={{
        code: '',
        password: '',
      }}
      validationSchema={confirmFormSchema}
      onSubmit={handleResetPassword}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        touched,
        handleChange,
        isSubmitting,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormInputsContainer>
            <TextFieldInputComponent
              fullWidth
              sizeVariant={GoogleLoginForInternalUser ? 'tall' : 'medium'}
              type="text"
              key="code"
              name="code"
              variant="outlined"
              onBlur={handleBlur}
              onChange={(e) => {
                setErrorMessage(''); // clear Auth error message when user starts typing again
                handleChange(e);
              }}
              label={I18n.get('Code')}
              placeholder={I18n.get('Code')}
              error={Boolean((touched.code && errors.code) || errorMessage)}
              helperText={(touched.code && errors.code) || errorMessage || ' '}
              autoComplete="off"
              autoFocus
              inputProps={{
                'data-testid': 'code-input',
              }}
            />
            <TextFieldInputComponent
              fullWidth
              sizeVariant={GoogleLoginForInternalUser ? 'tall' : 'medium'}
              type="password"
              key="password"
              name="password"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              label={I18n.get('New password')}
              placeholder={I18n.get('New password')}
              error={Boolean(touched.password && errors.password)}
              helperText={
                (!GoogleLoginForInternalUser &&
                  touched.password &&
                  errors.password) ||
                ' '
              }
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                'data-testid': 'password-input',
              }}
            />
            {GoogleLoginForInternalUser && (
              <OnboardingFormError
                errorText={getFormErrorText(errors, touched) || errorMessage}
              />
            )}
            <Box width={1} mt={GoogleLoginForInternalUser ? 0 : 2.5}>
              <OnboardingButton
                size="large"
                fullWidth
                htmlId="forgot-password-submit"
                type="submit"
                color="primary"
                variant="contained"
                data-testid="submit-button"
                isLoading={isSubmitting}
              >
                {I18n.get('Reset password')}
              </OnboardingButton>
            </Box>
          </FormInputsContainer>
        </form>
      )}
    </Formik>
  );
};
