import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Auth, I18n } from 'aws-amplify';
import OnboardingButton from 'src/legacy/components/Onboarding/OnboardingButton';
import {
  BaseTextField,
  SharpOutlinedTextField,
} from 'src/legacy/components/TextField';
import { FormInputsContainer } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingLoginForm';
import { FlagsContext } from 'src/context';
import { OnboardingFormError } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingFormError';
import { getFormErrorText } from 'src/legacy/components/Onboarding/OnboardingForms/CreatePortalForm';

type AccountFormProps = {
  onSuccess: (deliveryMedium: string, email: string) => void;
};

export const AccountForm: React.FC<AccountFormProps> = ({ onSuccess }) => {
  const [isClient, setIsClient] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const TextFieldInputComponent = GoogleLoginForInternalUser
    ? SharpOutlinedTextField
    : BaseTextField;
  const handleSendCode = async (values: { username: string }) => {
    const { username: inputUsername } = values;
    if (!inputUsername) return;
    const username = inputUsername.toLowerCase();
    try {
      const data = await Auth.forgotPassword(username);

      if (!data) return;
      onSuccess(data?.CodeDeliveryDetails?.DeliveryMedium, username);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name && error.name === 'UserNotFoundException') {
          setErrorMessage('Incorrect username');
        } else {
          setErrorMessage(error.message);
        }
      }
    }
  };

  useEffect(() => {
    setIsClient(true);
  }, [isClient === false]);

  return (
    <Formik
      initialValues={{
        username: '',
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .email('Invalid email')
          .max(100)
          .required(
            // API errors take priority over schema validation errors.
            errorMessage || 'Email is required',
          ),
      })}
      onSubmit={async (values, { resetForm }) => {
        try {
          await handleSendCode(values);
          resetForm();
        } catch (error) {
          resetForm();
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        touched,
        handleChange,
        isSubmitting,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormInputsContainer>
            <TextFieldInputComponent
              fullWidth
              sizeVariant={GoogleLoginForInternalUser ? 'tall' : 'medium'}
              type="text"
              key={`username-${isClient ? 0 : 1}`}
              name="username"
              variant="outlined"
              onBlur={handleBlur}
              onInput={(e) => {
                setErrorMessage(''); // clear the Auth error when user starts typing again
                handleChange(e);
              }}
              label={I18n.get('Email')}
              placeholder={I18n.get('Email')}
              error={Boolean(
                (touched.username && errors.username) || errorMessage,
              )}
              helperText={
                (GoogleLoginForInternalUser &&
                  touched.username &&
                  errors.username) ||
                errorMessage ||
                ' '
              }
              autoComplete="off"
              autoFocus
              inputProps={{
                'data-testid': 'email-input',
              }}
            />
            {GoogleLoginForInternalUser && (
              <OnboardingFormError
                errorText={getFormErrorText(errors, touched) || errorMessage}
              />
            )}
            <Box mt={GoogleLoginForInternalUser ? 0 : 2.5}>
              <OnboardingButton
                size="large"
                fullWidth
                htmlId="forgot-password-send-code"
                type="submit"
                color="primary"
                variant="contained"
                isLoading={isSubmitting}
                data-testid="submit-button"
              >
                {I18n.get('Next')}
              </OnboardingButton>
            </Box>
          </FormInputsContainer>
        </form>
      )}
    </Formik>
  );
};
