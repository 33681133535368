import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import Button from 'src/legacy/components/Button';

interface CarouselLayoutProps {
  length: number;
  nextEnabled?: (currentStep: number) => boolean | string;
  step?: number;
  hiddenPrev?: boolean;
  hiddenNext?: boolean;
  hideAllActions?: boolean;
  finalActionButton?: React.ReactNode;
  carouselSecondaryAction?: React.ReactNode;
  carouselButtonClassName?: string;
  hideIndicators?: boolean;
  onStepChange?: (index: number) => void;
}

type StylesProps = {
  nextEnabled: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  carousel: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
  indicatorButton: {
    '& svg': {
      fontSize: 11,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      pointerEvents: (props: StylesProps) =>
        props.nextEnabled ? 'auto' : 'none',
    },
  },
}));

const CarouselLayout: React.FC<CarouselLayoutProps> = ({
  children,
  length,
  step,
  hiddenPrev = false,
  hiddenNext = false,
  hideAllActions = false,
  nextEnabled,
  finalActionButton,
  carouselSecondaryAction,
  carouselButtonClassName,
  hideIndicators = false,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(step || 0);
  const nextEnabledValue = nextEnabled && nextEnabled(currentIndex);
  const classes = useStyles({
    nextEnabled: Boolean(nextEnabledValue),
  });

  React.useEffect(() => {
    if (step !== undefined) setCurrentIndex(step);
  }, [step]);

  const handleChange = (index: number) => {
    if (index !== currentIndex) {
      setCurrentIndex(index);
    }
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const renderActionButtons = () => {
    if (hideAllActions) {
      return null;
    }

    return (
      <div>
        {currentIndex !== 0 && !hiddenPrev && (
          <Button
            className={carouselButtonClassName}
            htmlId="register-prev-btn"
            fullWidth
            color="primary"
            variant="contained"
          >
            Prev
          </Button>
        )}
        {currentIndex !== length - 1 && !hiddenNext && (
          <Button
            className={carouselButtonClassName}
            htmlId="register-next-btn"
            fullWidth
            color="primary"
            variant="contained"
            disabled={!nextEnabledValue}
            onClick={handleNext}
          >
            Next
          </Button>
        )}
        {currentIndex === length - 1 && finalActionButton && (
          <div>{finalActionButton}</div>
        )}

        {carouselSecondaryAction}
      </div>
    );
  };

  return (
    <div className={classes.carouselContainer}>
      <Carousel
        timeout={0}
        className={classes.carousel}
        index={currentIndex}
        onChange={handleChange}
        indicatorIconButtonProps={{
          className: classes.indicatorButton,
        }}
        navButtonsAlwaysInvisible
        autoPlay={false}
        indicators={!hideIndicators}
      >
        {children}
      </Carousel>
      {renderActionButtons()}
    </div>
  );
};

export default CarouselLayout;
