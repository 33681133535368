import * as React from 'react';
import useNavigate from 'src/hooks/useNavigate';

/**
 * A lightweight wrapper around the native `<a>` element
 * that provides client-side navigations.
 */
export function Link({
  className,
  href,
  onClick,
  ...rest
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) {
  const isExternal = Boolean(href?.match(/^https?:\/\//));
  const { navigate } = useNavigate();
  return (
    <a
      href={href}
      className={[className, 'text-current'].join(' ')}
      onClick={(e) => {
        if (!isExternal && href) {
          e.preventDefault();
          navigate(href);
        }
        onClick?.(e);
      }}
      {...rest}
    />
  );
}
