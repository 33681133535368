import React, { useContext } from 'react';
import { I18n } from 'aws-amplify';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  OnboardingFormProps,
  OnboardingFormValues,
} from 'src/legacy/components/Onboarding/OnboardingForms/onboardingFormTypes';
import { OnboardingButtonContainer } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingButtonContainer';
import {
  BaseTextField,
  SharpOutlinedTextField,
} from 'src/legacy/components/TextField';
import OnboardingButton from 'src/legacy/components/Onboarding/OnboardingButton';
import OtpInput from 'src/legacy/components/Auth/OtpInput';
import { MFA_CODE_LENGTH } from 'src/constants/authConsts';
import { FormInputsContainer } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingLoginForm';
import { FlagsContext } from 'src/context';

export interface OnboardingLoginChallengeFormProps extends OnboardingFormProps {
  challengeName?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    mfaChallenge: {
      paddingTop: '40px',
    },
  }),
);

/**
 * Onboarding login challenge form. This form is used to submit the challenge response
 * when going through the custom login flow and the user is required to enter a code
 */
export const OnboardingLoginChallengeForm = React.forwardRef<
  FormikProps<OnboardingFormValues>,
  OnboardingLoginChallengeFormProps
>(({ initialValues, handleSubmitDone, challengeName }, ref) => {
  const classes = useStyles();

  const schema = Yup.object()
    .strict(true)
    .shape({
      challengeResponse: Yup.string()
        .required('Code must be exactly 6 digits')
        .matches(/^[0-9]+$/, 'Code must be only digits')
        .min(6, 'Code must be exactly 6 digits')
        .max(6, 'Code must be exactly 6 digits'),
    });

  const isMfaChallenge = challengeName === 'SOFTWARE_TOKEN_MFA';
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const TextFieldComponent = GoogleLoginForInternalUser
    ? SharpOutlinedTextField
    : BaseTextField;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmitDone}
      innerRef={ref}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
        setFieldValue,
        submitForm,
      }) => (
        <form noValidate onSubmit={handleSubmit} data-testid="testingform">
          <FormInputsContainer>
            {isMfaChallenge ? (
              <OtpInput
                value={values.challengeResponse}
                onChange={async (otp) => {
                  await setFieldValue('challengeResponse', otp, true);

                  // auto-verify otp when last digit is filled
                  if (otp.length === MFA_CODE_LENGTH) {
                    await submitForm();
                  }
                }}
                error={touched.challengeResponse && errors.challengeResponse}
                className={classes.mfaChallenge}
              />
            ) : (
              <TextFieldComponent
                InputProps={{
                  'data-testid': 'challengeResponseInput',
                }}
                sizeVariant="tall"
                id="login-challengeResponse"
                autoFocus={!initialValues.email}
                fullWidth
                type="text"
                key="challengeResponse"
                name="challengeResponse"
                variant="outlined"
                onBlur={handleBlur}
                onInput={handleChange}
                value={values.challengeResponse}
                label={I18n.get('Verification code')}
                placeholder={
                  GoogleLoginForInternalUser
                    ? I18n.get('Verification code')
                    : ''
                }
                error={Boolean(
                  touched.challengeResponse && errors.challengeResponse,
                )}
                helperText={
                  (touched.challengeResponse && errors.challengeResponse) || ' '
                }
                autoComplete="off"
              />
            )}
            <OnboardingButtonContainer>
              <OnboardingButton
                type="submit"
                isLoading={isSubmitting}
                data-testid="submit-button"
                color="primary"
                progressColor={isMfaChallenge ? 'primary' : 'secondary'}
                variant={isMfaChallenge ? 'outlined' : 'contained'}
                fullWidth
                htmlId="submit-challenge-response-button"
                disabled={
                  isMfaChallenge &&
                  values.challengeResponse?.length !== MFA_CODE_LENGTH
                }
              >
                {I18n.get(isMfaChallenge ? 'Verify Token' : 'Continue')}
              </OnboardingButton>
            </OnboardingButtonContainer>
          </FormInputsContainer>
        </form>
      )}
    </Formik>
  );
});
