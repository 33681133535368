import { Body } from 'copilot-design-system';
import { ArrowNE } from 'copilot-design-system/dist/icons';
import React from 'react';
import { Link } from 'src/components/Link';
import { Callout } from 'src/legacy/components/Callout';

export const DomainMigrationCallout = () => {
  return (
    <Callout
      variant="info"
      hideIcon
      className="w-fill bg-white max-w-[627px] mx-auto rounded gap-2"
    >
      <Body className="text-black-heading font-bold">
        The Copilot Dashboard has a new location
      </Body>
      <Body tag="span" className="text-black-heading">
        We moved to dashboard.copilot.<strong>app</strong> from
        dashboard.copilot.<strong>com</strong> and the old link will stop
        working soon. If you were previously logged in you may have to
        authenticate again. The client portal location is unchanged, so your
        clients’ experience remains the same.
      </Body>

      <Link
        target="_blank"
        className="text-link cursor-pointer mt-2 no-underline"
        href="https://www.copilot.app/guide/new-copilot-url"
      >
        <span className="hidden sm:flex items-center mt-2">
          <Body className="text-[14px]" tag="span">
            Learn more
          </Body>
          <ArrowNE className="h-[13px] w-[13px] ml-[6px]" />
        </span>
      </Link>
    </Callout>
  );
};
